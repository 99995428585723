import {css} from "../_snowpack/pkg/lit-element.js";
export default css`
::slotted(h1),
::slotted(h2) {
  margin: 0 0 20px;
  font-size: 30px;
}
@media (max-width: 640px) {
  ::slotted(h1),
::slotted(h2) {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

:host {
  position: fixed;
  top: 0px;
  height: 0;
  width: 100%;
  left: 0;
  z-index: 1000;
  display: block !important;
  --background-color: black;
  --color: white;
  --highlight-color: red;
  --highlight-text-color: blue;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.wrapper {
  background: var(--background-color);
  color: var(--color);
  padding: 50px;
  pointer-events: all;
  line-height: 1.3;
  height: auto;
  flex-grow: 0;
  max-width: 800px;
  position: relative;
  z-index: 1;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform-origin: 0 0;
  transform: translateX(-100%);
  overflow: hidden;
}
@media (max-width: 900px) {
  .wrapper {
    padding: 30px;
  }
}
@media (max-width: 640px) {
  .wrapper {
    padding: 15px;
  }
}

.inner {
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform-origin: 0 0;
  transform: translateX(100%);
}

.container {
  height: 100vh;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.container.active {
  pointer-events: all;
}
.container.active .wrapper,
.container.active .inner {
  transform: translateX(0);
}
.container.active .backdrop {
  opacity: 1;
}

.checkbox-container {
  margin: 20px 0 30px;
}

.checkbox-row {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.checkbox-row ::slotted(p) {
  margin: 0;
}

input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  border-radius: 50px;
  transition: all 0.4s;
  background: #ccc;
  margin-right: 10px;
}
input.apple-switch[disabled] {
  opacity: 0.2;
}

input.apple-switch:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background: white;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: all 0.4s;
}

input.apple-switch:checked {
  background: var(--highlight-color);
}

input.apple-switch:checked:after {
  left: 21px;
}

.button-row {
  display: flex;
  margin-left: -10px;
}

button {
  display: flex;
  overflow: hidden;
  margin: 10px;
  padding: 12px 28px;
  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: uppercase;
  border: 0 none;
  font-size: 14px;
  border-radius: 3px;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  background-color: var(--highlight-color);
  color: var(--highlight-text-color);
  transition: all 150ms linear;
}
button:hover {
  background-color: var(--hover-color);
  opacity: 1;
}
button:focus {
  outline: 0;
}
button.secondary {
  background-color: var(--secondary-color);
}
button.secondary:hover {
  background-color: var(--hover-color);
  opacity: 1;
}`;
